<template>
  <div id="app">
    <b-navbar toggleable="lg" id="navbar" sticky>
      
      <b-container>
      <!--<b-navbar-brand href="#">Alexander Rudy</b-navbar-brand>-->
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav id="nav">
          <b-nav-item @click="scrollTo('aboutme')">About Me</b-nav-item>
          <b-nav-item @click="scrollTo('recent')">Recent Works</b-nav-item>
          <b-nav-item @click="scrollTo('contact')">Contact Me</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      </b-container>
    </b-navbar>

    <b-container>
      <b-row>
        <b-col>
          <div id="aboutme">
            <h1>About Me</h1>
            <b-card>
              <h3>Alexander Rudy - Kunstmaler</h3>
              <br>
              <b-row>
                <b-col cols="9">
                  <table>
                    <col style="width: 20%;" />
                    <col style="width: 80%;" />

                    <tr><th>12.1970</th><th>in St. Petersburg, Russland geboren</th></tr>
                      
                    <tr><th>1984 - 1989</th><th>Ausbildung am Staatlichen Lyzeum der Kunst an der Hochschule n. I. E. Repin</th></tr>
                    <tr><th></th><th>Abitur</th></tr>
                      
                    <tr><th>1991 - 1997</th><th>Studium an der Staatlichen Kunsthochschule für Malerei, Bildhauerei und Architektur "I.Repin" in St. Petersburg</th></tr>
                      
                    <tr><th>seit 1998</th><th>Freischaffender Kunstmaler und Kunstpädagoge - Dozent an verschiedenen Kunstschulen</th></tr>
                      
                    <tr><th>seit 2003</th><th>Leiter der Freien Kunstschule Köln</th></tr>
                  </table> 
                  
                  <br>
                </b-col>
                <b-col cols="3">
                  <b-img rounded fluid src="./assets/profil.jpg"></b-img>
                  <br>
                </b-col>
                <div class="w-100"></div>
                <b-col>
                  <h4>Ausstellungen und Ausstellungsbeteiligungen in:</h4>
                  <ul>
                    <li>Sankt Petersburg</li>
                    <li>Genf</li>
                    <li>Turin</li>
                    <li>Jena</li>
                    <li>Eisenberg</li>
                    <li>Kahla</li>
                    <li>Ranis</li>
                    <li>Gelsenkirchen</li>
                    <li>Köln</li>
                    <li>Bad-Münstereifel</li>
                  </ul>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
        <div class="w-100"></div>
        <b-col>
          <div id="recent">
            <h1>Recent Works</h1>
            <b-card>
              <b-row>
                <b-col cols="6" md="4" xl="3" v-for="(item, index) in images" :key="index" style="padding: 0.5rem;" @click="openGalery('img_'+(index+1))">
                  <b-aspect :aspect="`1:1`" class="img_container rounded border">
                    <b-img fluid-grow :src="`/images/${item.image}`" :alt="`Image ${index+1}`" class="img_img"></b-img>
                  </b-aspect>
                </b-col>
                <b-col v-if="noimages">
                <p>Please follow me on <a href="https://instagram.com/alexander_rudy_painter">Instagram</a> for my recent works!</p>
                </b-col>
              </b-row>
            </b-card>
            <b-modal v-model="galeryShow" id="galery" size="lg" hide-header hide-footer no-stacking>
              <div v-for="(item, index) in images" :key="index" class="modal_img">
              <b-img fluid-grow :src="`/images/${item.image}`" :alt="`Image ${index+1}`" :id="`img_${index+1}`" class="rounded border"></b-img>
              </div> <!--${publicPath}-->
            </b-modal>
          </div>
        </b-col>
        <div class="w-100"></div>
        <b-col>
          <div id="contact">
            <h1>Contact Me</h1>
            <b-card>
              <p>Sie interessieren sich für meine Arbeiten - haben Fragen zu meinen Zeichen- und Malkursen oder wollen einfach nur Ihre Gedanken mitteilen?</p>
              <p>Schreiben Sie mir eine E-Mail an <a href="mailto:kontakt@alexander-rudy.de">kontakt@alexander-rudy.de</a>
              oder rufen Sie mich an unter Tel.: 0221-259 34 01.</p>
              <p>Vielen Dank für Ihr Interesse! <br>
              Alexander Rudy</p>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-navbar id="footer">
      <b-container>
        <b-navbar-nav class="mx-auto">
          <b-nav-item> </b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
      images: [],
      galeryShow: false,
    }
  },
  methods: {
    getImages() {
      axios.get("images/images.json").then(response => (this.images = response.data));
    },
    scrollTo: function(id) {
      const elmnt = document.getElementById(id);
      this.$smoothScroll({
        scrollTo: elmnt,
        duration: 1000, 
        offset: -80
      }) 
    },
    openGalery: async function(id) {
      this.galeryShow = true;
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      await sleep(500);
      //document.getElementById(id).scrollIntoView({behaviour: 'smooth'});
      //document.getElementById(id).scrollIntoView();
      const elmnt = document.getElementById(id);
      this.$smoothScroll({
        scrollTo: elmnt,
        duration: 700,
        container: document.getElementById('galery'),
        easingFunction: `linear`
      })
      await sleep(700);
      this.$smoothScroll({
        scrollTo: elmnt,
        duration: 700,
        offset: -10,
        container: document.getElementById('galery')
      }) 
    }
  },
  computed: {
    noimages() {
      if (this.images.length == 0){
        return true
      }
      return false
    }
  },
  created() {
    document.title = ":: Alexander Rudy - Kunstmaler & Grafiker :: Zeichen- und Malkurse, Malschule, Kunstschule ::";
    this.getImages();
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  background-color: #3C92B7;
  font-size: 1.2em;
  /*margin-top: 60px;*/
}

.card {
  background-color:rgba(255, 255, 255, 0.85);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#nav{
  /*margin-left: 30px;*/
  li {
    /*margin-left: 30px;*/
    margin-right: 60px;
  }
}

h1 {
  margin-top: 1rem;
}

.navelements {
  margin-left: 30px;
  margin-right: 30px;
}

table {
  tr {
    th {
      font-weight: normal;
    }
  }
}

.img_container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.img_img {
  position: absolute;
  min-height: 100%;
  width: 100% ;
  transition: transform .75s; /* Animation */

  &:hover {
    transform: scale(1.15); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

.modal_img {
    padding: 1rem;
  }

#footer, #navbar {
  background-color: #99aab5;
  font-size: 1.3rem;
  font-weight: 500;
}
</style>
